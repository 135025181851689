<script>
import api from '@/command/api'
import moment from 'moment'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { Button, DatePicker, Modal, Calendar } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import { deleteAction, getAction, postAction } from '@/command/netTool'
import { getAllDates } from '@/utils/dateHandle'
export default {
  name: 'panel-calendar',
  data() {
    return {
      ...api.command.getState(),
      yearMonth: moment(new Date()).format('YYYY-MM-DD'),
      productName: this.$route?.query?.productName || '',
      productComboIdList: [],
      dateList: [],
      weekList: [
        {
          name: '星期一',
          value: '1',
        },
        {
          name: '星期二',
          value: '2',
        },
        {
          name: '星期三',
          value: '3',
        },
        {
          name: '星期四',
          value: '4',
        },
        {
          name: '星期五',
          value: '5',
        },
        {
          name: '星期六',
          value: '6',
        },
        {
          name: '星期日',
          value: '7',
        },
      ],
      dayList: [],
      productId: '',
      productType: '',
      dateArr: [],
    }
  },
  mounted() {
    const { productId, productType } = this.$route.query
    this.productId = productId
    this.productType = productType
    this.queryComboDetails()
    this.getInit()
    for (let i = 1; i <= moment(new Date()).daysInMonth(); i++) {
      this.dayList.push({
        name: i,
        value: `${i}`,
      })
    }
  },
  methods: {
    queryComboDetails() {
      const { productId } = this.$route.query
      getAction('/api/goods/farmTicketPriceConfig/queryComboDetails', { productComboId: productId }).then((e) => {
        this.productComboIdList = e.data
      })
    },
    getWeek(dateArr) {
      if (!dateArr.length) return
      let arr = [...new Set(dateArr.map((e) => moment(e).day()))]
      this.weekList = this.weekList.map((e) => {
        if (e.value == '7') {
          return {
            ...e,
            disabled: !arr.includes(0),
          }
        } else {
          return {
            ...e,
            disabled: !arr.includes(Number(e.value)),
          }
        }
      })
    },
    getWeek2(dateArr) {
      if (!dateArr.length) return
      let arr = [...new Set(dateArr.map((e) => moment(e).format('D')))]
      this.dayList = this.dayList.map((e) => {
        return {
          ...e,
          disabled: !arr.includes(e.value),
        }
      })
    },
    getInit() {
      getAction(
        '/goods/farmTicketPriceConfig/querySimpleCalendarList',
        {
          productId: this.productId,
          yearMonth: moment(this.yearMonth).format('YYYY-MM'),
        },
        '/api'
      ).then(({ data }) => {
        this.dateList = data
      })
    },
    onPanelChange(value, mode) {
      this.yearMonth = value
      this.getInit()
    },
    onSelect(value) {
      this.yearMonth = value
      const filterDay = (this.dateList.filter((e) => e.date === value) || [{}])[0]
      const { productId } = this.$route.query
      getAction('/api/goods/farmTicketPriceConfig/getSingleComboDetail', {
        productComboId: productId,
        date: filterDay.date,
      }).then(({ data }) => {
        let selectList = data.details
        apiTool.showModal({
          width: '900px',
          title: '日历价格设置',
          success: ({ data, setHidden }) => {
            if (!data.salePrice) {
              this.$message.warning('请输入销售价!')
              return
            }
            let obj = Object.assign({}, data, {
              productId: this.productId,
              productType: this.productType,
            })
            console.log('data', data)
            // const state = data.comboDetails.every((e) => parseFloat(e.originalPrice) && parseFloat(e.salePrice))
            // if (!state) {
            //   this.$message.error('请先设置原价和销售价')
            //   return
            // }
            postAction('/goods/farmTicketPriceConfig/saveByDate', obj, '/api').then((res) => {
              if (res.code == 200) {
                this.getInit()
                this.$message.success('操作成功')
                setHidden()
              } else {
                this.$message.error('操作失败')
              }
            })
          },
          formData: {
            ...filterDay,
            name: this.productName,
            date: value,
            comboDetails: selectList,
          },
          form: [
            {
              name: '商品名',
              type: 'text',
              key: 'name',
              labelCol: { span: 5 },
              wrapperCol: { span: 19 },
            },
            {
              name: '日期',
              type: 'text',
              key: 'date',
              labelCol: { span: 5 },
              wrapperCol: { span: 19 },
            },
            {
              name: '销售价',
              type: 'inputNumber',
              key: 'salePrice',
              labelCol: { span: 5 },
              wrapperCol: { span: 19 },
              rules: [{ required: true, type: 'number' }],
              props: {
                min: 0,
                disabled:true
              },
            },
            {
              name: '表格',
              type: 'table',
              columns: this.getColumns(),
              labelCol: { span: 5 },
              wrapperCol: { span: 19 },
              dataSource: selectList,
              display: true,
              cols: 24,
              key: 'comboDetails',
              showPagination: false,
            },
          ],
        })
      })
    },

    showConfirm() {
      Modal.confirm({
        title: '提示',
        content: '此操作将删除该商品所有价格, 是否继续？',
        onOk: () => {
          deleteAction(
            '/goods/farmTicketPriceConfig/clearALL?productId=' + this.productId + '&productType=' + this.productType,
            {},
            '/api'
          ).then((res) => {
            if (res.code == 200) {
              this.getInit()
              this.$message.success('操作成功')
            } else {
              this.$message.error('操作失败')
            }
          })
        },
        onCancel() {},
      })
    },
    getHeaderRender() {
      return (
        <div class="header-button">
          <span>
            <Button type="primary" onClick={this.batchSet}>
              批量设置
            </Button>
            <Button style={{ marginLeft: '12px' }} type="default" onClick={this.showConfirm}>
              清除报价
            </Button>
          </span>
        </div>
      )
    },
    getColumns() {
      return [
        {
          dataIndex: 'productName',
          title: '名称',
        },
        {
          dataIndex: 'syncFlag',
          title: '是否同步',
          width: 100,
          customRender: function (text, records) {
             return text == 1 ? '是' : '否'
          },
        },
        {
          dataIndex: 'originalPrice',
          title: '原价',
          width: 150,
          customRender: function (text, records) {
            const onInput = (data) => {
              if (data.target.value) {
                if (!/[0-9\.]/.test(data.target.value)) {
                  data.target.value = null
                  that.$message.warning('请输入数字！')
                  return false
                } else if (data.target.value < 0) {
                  that.$message.warning('请输入正数！')
                  data.target.value = null
                  return false
                } else {
                  records.originalPrice = data.target.value
                }
              } else {
                records.originalPrice = ''
              }
            }
            return (
              <a-input
                type="number"
                placeholder="请输入原价"
                addon-before="￥"
                onChange={onInput}
                value={text}
                disabled={true}
                min={0}
              />
            )
          },
        },
        {
          title: '数量',
          dataIndex: 'productQuantity',
          width: 50,
        },
        {
          dataIndex: 'jsPrice',
          title: '结算价',
          width: 150,
          customRender: function (text, records) {
            const onInput = (data) => {
              if (data.target.value) {
                if (!/[0-9\.]/.test(data.target.value)) {
                  data.target.value = null
                  that.$message.warning('请输入数字！')
                  return false
                } else if (data.target.value < 0) {
                  that.$message.warning('请输入正数！')
                  data.target.value = null
                  return false
                } else {
                  records.jsPrice = data.target.value
                }
              } else {
                records.jsPrice = ''
              }
            }
            return (
              <a-input
                type="number"
                placeholder="请输入结算价"
                addon-before="￥"
                onChange={onInput}
                value={text}
                min={0}
                disabled={records.syncFlag == 1}
              />
            )
          },
        },
        {
          dataIndex: 'salePrice',
          title: '销售价',
          width: 150,
          customRender: function (text, records) {
            const onInput = (data) => {
              if (data.target.value) {
                if (!/[0-9\.]/.test(data.target.value)) {
                  data.target.value = null
                  that.$message.warning('请输入数字！')
                  return false
                } else if (data.target.value < 0) {
                  that.$message.warning('请输入正数！')
                  data.target.value = null
                  return false
                } else {
                  records.salePrice = data.target.value
                }
              } else {
                records.salePrice = ''
              }
            }
            return (
              <a-input
                type="number"
                placeholder="请输入销售价"
                addon-before="￥"
                onChange={onInput}
                value={text}
                min={0}
                disabled={records.syncFlag == 1}
              />
            )
          },
        },
      ]
    },
    getForm(formInfo) {
      return [
        {
          name: '日期',
          type: 'rangePicker',
          keys: ['startDate', 'endDate'],
          ranges: {
            最近一周: [moment(), moment().add(1, 'weeks')],
            最近一个月: [moment(), moment().add(1, 'month')],
            最近三个月: [moment(), moment().add(3, 'month')],
          },
          labelCol: { span: 4 },
          wrapperCol: { span: 15 },
          onChange: (first) => {
            if (first.length) {
              let startTime = moment(first[0]).format('YYYY-MM-DD')
              let endTime = moment(first[1]).format('YYYY-MM-DD')
              this.dateArr = getAllDates(startTime, endTime)
              this.getWeek(this.dateArr)
              this.getWeek2(this.dateArr)
            } else {
              this.dateArr = []
            }
          },
        },
        {
          name: '日期方式',
          type: 'radioButton',
          key: 'dateType',
          labelCol: { span: 4 },
          wrapperCol: { span: 19 },
          typeData: [
            {
              name: '全部',
              value: '0',
            },
            {
              name: '指定星期',
              value: '1',
            },
            {
              name: '指定日期',
              value: '2',
            },
          ],
          onChange: () => {
            formInfo.dateValue = []
          },
        },
        ...[
          {
            name: '选择星期',
            type: 'checkBoxButton',
            key: 'dateValue',
            labelCol: { span: 4 },
            wrapperCol: { span: 19 },
            typeData: this.weekList,
            display: formInfo.dateType == '1',
          },
          {
            name: '选择日期',
            type: 'checkBoxButton',
            key: 'dateValue',
            labelCol: { span: 4 },
            wrapperCol: { span: 19 },
            typeData: this.dayList,
            display: formInfo.dateType == '2',
            styles: {
              width: '80px',
            },
          },
          {
            name: '销售价',
            type: 'input',
            key: 'salePrice',
            labelCol: { span: 4 },
            wrapperCol: { span: 19 },
            display: true,
            styles: {
              width: '100%',
            },
            props:{
              disabled:true
            }
          },

          {
            name: '表格',
            type: 'table',
            columns: this.getColumns(),
            labelCol: { span: 4 },
            wrapperCol: { span: 19 },
            dataSource: this.productComboIdList,
            display: true,
            cols: 24,
            key: 'comboDetails',
            showPagination: false,
          },
        ].filter((e) => e.display),
      ]
    },
    batchSet() {
      const salePrice = this.productComboIdList.reduce(((pre,next)=> pre + (next.salePrice*next.productQuantity)),0)
      let formData = {
        startDate: '',
        endDate: '',
        dateType: '',
        dateValue: '',
        comboDetails: this.productComboIdList,
        salePrice,
      }
      let flag = true
      apiTool.showModal({
        width: '900px',
        title: '日历价格设置',
        formData,
        form: () => this.getForm(formData),
        success: ({ data, setHidden }) => {
          if (!flag) return
          flag = false

          if (!data.salePrice) {
            this.$message.warning('请输入销售价!')
            flag = true
            return
          }
          let params = {
            ...data,
            skuPriceList: [{ salePrice: data.salePrice, comboDetails: data.comboDetails }],
            dateValue: data.dateValue.toString(),
            productId: this.productId,
            productType: this.productType,
          }
          console.log(params, '批量设置')
          const loadingNotice = this.$message.loading('提交中,请稍等', 0)

          postAction('/goods/farmTicketPriceConfig/saveBatch', params, '/api')
            .then((res) => {
              flag = true

              loadingNotice()
              if (res.code == 200) {
                this.getInit()

                this.$message.success('操作成功')
                setHidden()
              } else {
                this.$message.error('操作失败')
              }
            })
            .catch((err) => {
              loadingNotice()

              flag = true
            })
        },
      })
    },
  },
  render() {
    let i = 0
    return (
      <div class="body-card">
        {this.getHeaderRender()}
        <div class="title">{this.productName}</div>
        <Calendar
          value={this.yearMonth}
          valueFormat="YYYY-MM-DD"
          onPanelChange={this.onPanelChange}
          onSelect={this.onSelect}
          disabledDate={(data) => {
            const filter = this.dateList.filter((e) => e.date === moment(data).format('YYYY-MM-DD'))
            return filter.length > 0 ? false : true
          }}
          scopedSlots={{
            dateCellRender: (date, a) => {
              const day = moment(date).format('YYYY-MM-DD')
              const filterDay = this.dateList.filter((e) => e.date === day)
              const price = filterDay && filterDay.length > 0 ? filterDay[0] : {}
              const data = [
                {
                  name: '原价',
                  value: price.originalPrice ?? 0,
                },
                {
                  name: '销售价',
                  value: price.salePrice ?? 0,
                },
                {
                  name: '结算价',
                  value: price.jsPrice ?? 0,
                },
                {
                  name: '库存',
                  value: price.unUsedStock ?? 0,
                },
              ]
              return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  {data.map((e) => {
                    return (
                      <div>
                        {e.name}
                        {e.value}
                      </div>
                    )
                  })}
                </div>
              )
            },
          }}
        />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
/deep/.ant-card-body {
  padding-top: 4px;
}
/deep/.ant-radio-group-default {
  display: none !important;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.body-card {
  border: 1px solid rgba(232, 234, 236, 1);
  border-radius: 4px;
  min-height: calc(100vh - 128px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  margin-bottom: 50px;
}
/deep/ .template-card {
  border: 0;
  min-height: calc(100vh - 200px);
}
/deep/.ant-btn {
  border-radius: 5px;
}
.calendar_name {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  padding: 24px 24px 0 24px;
  i {
    cursor: pointer !important;
  }
  span {
    display: flex;
    align-items: center;
  }
}
.product-order-item-copy {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  height: 55px;

  &.yes {
    cursor: pointer;

    &:hover {
      background-color: #e5e5e5;
    }
  }

  &.no {
    cursor: not-allowed;
  }
}
/deep/
  .ant-table-middle
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  padding: 0;
}
</style>
